<template>
  <div class="page-profile-edit">
    <back />
    <div class="wrap-inside">
      <n-loader :loading="$var('load')" />
      <div class="header">
        <img :src="editProfileImg" alt="">
        <div class="container">
          <div class="content">
            <div class="title"><b>Редактирование <br> профиля</b></div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="title">Профиль</div>
        <n-form class="form" @submit="() => isStore ? submitStore() : submit()">
          <n-items>
            <n-input v-if="isStore" title="Название магазина" v-bind="$form.input('title')" />
            <n-input v-else title="Ваше имя" v-bind="$form.input('fullName')" />
            <n-input title="Ваш адрес" v-bind="$form.input('address')" />
            <div class="address-block">
              <n-input title="Номер квартиры" v-bind="$form.input('flat')" />
              <n-input title="Номер подъезда" v-bind="$form.input('door')" />
            </div>
            <n-input title="Дополнительная информация" v-bind="$form.input('comment')" />
            <n-button color="primary" type="submit">Сохранить</n-button>
          </n-items>
        </n-form>
      </div>
    </div>
  </div>
</template>

<script>
import editProfileImg from 'assets/edit-profile.png'

export default {
  name: 'PageProfileEdit',
  data() {
    return {
      editProfileImg,
    }
  },
  computed: {
    isStore() {
      return $app.auth.user().store
    },
    model() {
      const user = $app.auth.user()
      return user.store || user
    },
  },
  created() {
    this.$form.init(this.model)
  },
  methods: {
    submit() {
      this.$var('load', true)
      const data = {
        fullName: this.$form.get('fullName'),
        address: this.$form.get('address'),
        flat: this.$form.get('flat'),
        door: this.$form.get('door'),
        comment: this.$form.get('comment'),
      }
      $api.profile.edit(data).then((response) => {
        $app.auth.user(response.data.content)
        this.$router.push({ name: 'profile.index', })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    submitStore() {
      this.$var('load', true)
      const data = {
        title: this.$form.get('title'),
        address: this.$form.get('address'),
        flat: this.$form.get('flat'),
        comment: this.$form.get('comment'),
      }
      $api.profile.store.edit(data).then((response) => {
        $app.auth.user({
          ...$app.auth.user(),
          store: response.data.content,
        })
        this.$router.push({ name: 'profile.index', })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-profile-edit {
  .wrap-inside {
    position: relative;
  }

  .header {
    position: relative;
    min-height: 205px;
    padding: 60px 0 20px;
    margin-bottom: 4px;
    overflow: hidden;

    img {
      position: absolute;
      top: -50px;
      left: 0;
      max-width: 100%;
    }

    .content {
      position: relative;
      z-index: 2;
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #000;

    b {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .form {
    padding-top: 15px;
  }

  .address-block {
    width: 100%;
    display: flex;
    &>*:not(:last-child) {
      margin-right: 20px;
    }
  }

  .n-button{
    width: 100%;
    border-radius: 100px;
  }
}
</style>
